<!--
 * @Author: your name
 * @Date: 2022-04-24 16:10:30
 * @LastEditTime: 2022-04-27 15:25:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/App.vue
-->
<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
			<router-view />
		</div>
	</a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')
export default {
	data() {
		return {
			locale: zhCN,
		}
	},
}
</script>
<style lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	width: 100%;
	height: 100%;
}
html,
body {
	height: 100%;
	margin: 0;
}
#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
.modal-title {
	height: 56px;
	width: 100%;
	background: #1890ff;
	text-align: center;
	line-height: 56px;
	color: #ffffff;
	font-size: 16px;
}
.modal-body-box {
	margin: 30px auto 50px auto;
}
.query-button {
	padding: 0px 21px 0px 17px;
	background: #ffffff;
}
.col-label {
	/* width: 100px;
  text-align: right; */
	font-size: 14px;
	color: #333333;
	width: 100px;
	text-align: right;
	margin-right: 20px;
}
.must::after {
	content: '*';
	color: #ff4d4f;
	position: absolute;
}
.col-flex {
	display: flex !important;
	align-items: center;
}
.ant-modal {
	border-radius: 4px !important;
}
.ant-modal-header {
	height: 56px !important;
	background: #1890ff !important;
	text-align: center !important;
}
.ant-modal-title {
	color: #ffffff !important;
}
.ant-modal-close {
	color: #ffffff !important;
}
.ant-modal-footer {
	border-top: 0px !important;
}
.page-head-title {
	padding: 20px 0px;
	font-size: 15px;
	font-weight: 400;
	color: #333333;
	display: flex;
	align-items: center;
}
.page-head-title-icon {
	width: 4px;
	height: 14px;
	background: #1890ff;
	margin-right: 8px;
}
// 表格样式
.borderTable div,
.borderTable table {
	border-radius: 0;
}

.borderTable .ant-table-thead > tr > th,
.borderTable .ant-table-tbody > tr > td {
	// padding: 9px 16px 10px 16px;
	border: 0;
}

.borderTable .ant-table-thead > tr > th {
	background: rgb(224, 226, 229);
}

.borderTable .ant-table-tbody tr:nth-child(2n) .ant-table-cell {
	background: rgb(240, 242, 245);
}

.borderTable .ant-table-tbody > tr.ant-table-row-selected td {
	background: rgb(230, 247, 255);
}


.borderTable .ant-table-pagination.ant-pagination {
	margin: 10px 0 0 0;
	padding: 0 5px;
}
.borderTable table .ant-table-tbody tr:nth-child(2n) {
    background-color: rgb(255,255,255);
}
.borderTable table tr:nth-child(2n) td {
    background-color: rgb(240, 242, 245);
}
.ant-pagination {
	position: absolute;
	right: 50px;
	margin-top: 30px !important; 
}
.ant-modal-footer {
	margin-top: 30px !important;
}
</style>
