/*
 * @Author: zwb 1172031304@qq.com
 * @Date: 2022-04-27 15:01:45
 * @LastEditors: zwb 1172031304@qq.com
 * @LastEditTime: 2022-11-15 11:34:44
 * @FilePath: /jyws_vue/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue/es';
import router from './router'
import moment from "moment"
import * as echarts from 'echarts'; 
import 'ant-design-vue/dist/antd.css';
import Print from 'vue-print-nb' // 打印
Vue.use(Antd);
Vue.use(Print)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
