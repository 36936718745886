/*
 * @Author: your name
 * @Date: 2022-04-22 11:25:04
 * @LastEditTime: 2022-11-30 10:38:15
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
  ,
  {
    path: '/diyBoard', // diy大屏
    name: 'DiyBoard',
    component: () => import(/* webpackChunkName: "about" */ '../views/diyBoard.vue')
  },
  {
    path: '/oplList', // opl列表
    name: 'OplList',
    component: () => import(/* webpackChunkName: "about" */ '../views/oplList/oplList.vue')
  },
  {
    path: '/technicalList', // 技术申请列表
    name: 'TechnicalList',
    component: () => import(/* webpackChunkName: "about" */ '../views/technicalList/technicalList.vue')
  },
  {
    path: '/distributorList', // 分销商管理
    name: 'DistributorList',
    component: () => import(/* webpackChunkName: "about" */ '../views/distributorList/distributorList.vue')
  },
  {
    path: '/successfulCustomers', // 成交客户管理
    name: 'SuccessfulCustomers',
    component: () => import(/* webpackChunkName: "about" */ '../views/successfulCustomers/successfulCustomers.vue')
  },
  {
    path: '/reportingCustomer', // 报备客户管理
    name: 'ReportingCustomer',
    component: () => import(/* webpackChunkName: "about" */ '../views/reportingCustomer/reportingCustomer.vue')
  },
  {
    path: '/specialEqus',  // 特种设备检验
    name: 'SpecialEqus',
    component: () => import(/* webpackChunkName: "about" */ '../views/specialEqus/specialEqus.vue')
  },
  {
    path: '/specialPeos',  // 特种证书检验
    name: 'specialPeos',
    component: () => import(/* webpackChunkName: "about" */ '../views/specialPeos/specialPeos.vue')
  },
  {
    path: '/specialRecordTabs', // 特种检验记录
    name: 'specialRecordTabs',
    component: () => import(/* webpackChunkName: "about" */ '../views/specialRecordTabs/specialRecordTabs.vue')
  },
  {
    path: '/outputManagement', // 产量管理
    name: 'outputManagement',
    component: () => import(/* webpackChunkName: "about" */ '../views/outputManagement/outputManagement.vue')
  },
  {
    path: '/outputBasicTabs', // 产量管理 --- 基础数据 
    name: 'outputBasicTabs',
    component: () => import(/* webpackChunkName: "about" */ '../views/outputBasicTabs/outputBasicTabs.vue')
  },
  {
    path: '/outputManagementTabs', // 产量管理 --- 生产管理 
    name: 'outputManagementTabs',
    component: () => import(/* webpackChunkName: "about" */ '../views/outputManagementTabs/outputManagementTabs.vue')
  },
  {
    path: '/safetyAccidentReport', // 安全事故报告
    name: 'SafetyAccidentReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/safetyAccidentReport/safetyAccidentReport.vue')
  },
  {
    path: '/numberOfDevices',
    name: 'NumberOfDevices',
    component: () => import(/* webpackChunkName: "about" */ '../views/numberOfDevices/numberOfDevices.vue')
  },
  {
    path: '/equipmentLifeCycle',
    name: 'EquipmentLifeCycle',
    component: () => import(/* webpackChunkName: "about" */ '../views/equipmentLifeCycle/equipmentLifeCycle.vue')
  },
  {
    path: '/redPacketRuleConfiguration',
    name: 'RedPacketRuleConfiguration',
    component: () => import(/* webpackChunkName: "about" */ '../views/redPacketRuleConfiguration/redPacketRuleConfiguration.vue')
  },
  {
    path: '/businessTransaction',
    name: 'BusinessTransaction',
    component: () => import(/* webpackChunkName: "about" */ '../views/adminPage/businessTransaction.vue')
  },
  {
    path: '/enterpriseWithdrawal',
    name: 'EnterpriseWithdrawal',
    component: () => import(/* webpackChunkName: "about" */ '../views/adminPage/enterpriseWithdrawal.vue')
  },
  {
    path: '/corporateGiftDistribution',
    name: 'CorporateGiftDistribution',
    component: () => import(/* webpackChunkName: "about" */ '../views/adminPage/corporateGiftDistribution.vue')
  },
  { // 生产-监控看板
    path: '/ProductionMonitoring',
    name: 'ProductionMonitoring',
    component: () => import(/* webpackChunkName: "about" */ '../views/productionMonitoring/productionMonitoring.vue')
  },
  { // 点检、保养、安全： 计划
    path: '/InspectionPlan',
    name: 'InspectionPlan',
    component: () => import(/* webpackChunkName: "about" */ '../views/inspectionPlan/inspectionPlan.vue')
  },
  { // 检修： 计划
    path: '/OverhaulPlan',
    name: 'OverhaulPlan',
    component: () => import(/* webpackChunkName: "about" */ '../views/inspectionPlan/overhaulPlan.vue')
  },
  { // 检修： 计划
    path: '/EquipmentBookAccount',
    name: 'EquipmentBookAccount',
    component: () => import(/* webpackChunkName: "about" */ '../views/equipmentBookAccount/equipmentBookAccount.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
