<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <draggable @start="start"
               @end="end">
      <p v-for="d in list"
         :key="d.id">{{d.name}}</p>
    </draggable>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import draggable from "vuedraggable";
export default {
  name: 'Home',
  components: {
    draggable
  },
  data() {
    return {
      list: [
        { name: 'John', id: 1 },
        { name: 'Joao', id: 2 },
        { name: 'Jean', id: 3 },
        { name: 'Gerard', id: 4 },
      ],
    }
  },
  methods: {
    start(e) {
      console.log(e)
    },
    end(e) {
      console.log(e)
    },
  },
}
</script>
